"use client";
import React from "react";
import Link from "next/link";
import { AnimatedTooltip } from "@/components/ui/animated-tooltip";

import Image from "next/image";
import Tag from "@/components/shared/Tag";

interface TestimonialCardProps {
  quote: string;
  name: string;
  link: string;
  title: string;
  avatar: string;
  stats: { value: string; label: string }[];
  designation: string; // Add this line
}

const TestimonialCard: React.FC<TestimonialCardProps> = ({
  quote,
  name,
  link,
  title,
  avatar,
  stats,
  designation,
}) => {
  const person = [{ id: 1, name, link, designation, image: avatar }];

  return (
    <div className="flex h-auto w-full flex-col rounded-[32px] px-0 py-4 md:h-[280px] md:w-[540px] md:flex-row md:py-6">
      <div className="flex flex-grow flex-col items-center justify-between space-y-6 md:w-3/5 md:space-y-0">
        <div className="relative">
          <Image
            src="https://cdn-crayo.com/lp/public/quote.png"
            className="absolute -left-1 -top-3 z-10 opacity-40 md:-left-4 md:-top-2 lg:-left-4 lg:-top-3"
            alt="quote"
            width={60}
            height={60}
          />
          <p className="font-ambit-semibold text-md relative z-20 line-clamp-4 pl-6 pt-4 text-start leading-[125%] md:text-[28px]">
            {quote}
          </p>
        </div>
        <Link
          href={link}
          target="_blank"
          rel="noopener noreferrer"
          className="duration-400 flex flex-row items-center space-x-2.5 rounded-2xl px-4 py-2 transition-all ease-in-out hover:bg-crayoBlue/10"
        >
          <AnimatedTooltip items={person} />
          <div className="flex flex-col items-start pl-4 text-start">
            <div className="flex flex-row space-x-1">
              <p className="font-ambit-bold text-[15px] md:text-[18px]">{name}</p>
              <p className="font-ambit-regular text-[15px] md:text-[18px]">—</p>
              <p className="font-ambit-regular text-[15px] md:text-[18px]">Co-Founder, Crayo</p>
            </div>
            <p className="font-ambit-regular text-[14px] text-slate-400 md:text-[16px]">{title}</p>
          </div>
        </Link>
      </div>
      {/* <div className="mt-6 flex flex-row justify-center space-x-4 space-y-0 md:mt-0 md:w-2/5 md:flex-col md:space-x-0 md:space-y-4">
        {stats.map((stat, index) => (
          <div
            key={index}
            className="flex aspect-square w-1/2 flex-col items-center justify-center rounded-3xl bg-crayoBlue/10 md:w-full md:p-3"
          >
            <h6 className="text-[18px] font-bold md:text-[24px]">{stat.value}</h6>
            <p className="whitespace-nowrap text-xs md:text-sm">{stat.label}</p>
          </div>
        ))}
      </div> */}
    </div>
  );
};

const testimonials = [
  {
    id: 1,
    quote:
      "After running channels with over 1 million subscribers, I built Crayo to solve my own scaling problems.",
    name: "Daniel Bitton",
    link: "https://www.youtube.com/@danibitton",
    position: "Co-Founder, Crayo",
    designation: "Clipper + content creator",
    title: "Over 1 billion views generated on Shorts",
    avatar: "https://cdn-crayo.com/lp/public/testimonialsDaniel.png",
    stats: [
      { value: "14", label: "Pages auto-clipped" },
      { value: "$100k+", label: "Monthly revenue" },
    ],
  },
  {
    id: 2,
    quote:
      "I've clipped for some of the biggest creators on the internet. Crayo is the tool I wish I had when I started.",
    name: "Musa Mustafa",
    link: "https://www.instagram.com/mussy.02",
    position: "Co-Founder, Crayo",
    designation: "Clipper + content creator",
    title: "Clipped for SNEAKO, Sidemen, and more",
    avatar: "https://cdn-crayo.com/lp/public/testimonialsMusa.png",
    stats: [
      { value: "4x", label: "Faster clipping" },
      { value: "30%", label: "Increase in retention" },
    ],
  },
];

interface TestimonialsProps {
  index: number;
}

const Testimonials: React.FC<TestimonialsProps> = ({ index }) => {
  return (
    <div className="flex flex-col items-center justify-between space-y-12 px-4 py-20 text-center md:px-16 lg:px-40">
      <div className="flex w-full flex-col items-center justify-center">
        {index >= 0 && index < testimonials.length && <TestimonialCard {...testimonials[index]} />}
      </div>
    </div>
  );
};

export default Testimonials;
